<!--description:-->
<!--"获取影片榜单"-->
<template>
  <div class="report-plan">
    <van-nav-bar title="观影报告审批" left-arrow @click-left="$router.go(-1)" />
    <div>
      <van-tabs
        type="line"
        color="#1492FF"
        line-height="0px"
        line-width="0"
        title-class="tab-title"
        swipeable
        background="#EEF0F3"
        animated
      >
        <van-tab>
          <template #title>
            <van-button
              @click="activeIndex = 0"
              style="width: 148px"
              block
              :type="activeIndex == 0 ? 'info' : 'default'"
              size="small"
              :color="activeIndex == 0 ? '#1492FF' : '#B3B5B9'"
              >待评价</van-button
            >
          </template>
          <div class="report-plan__content">
            <div class="report-plan-list">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="reportList.length > 0"
              >
                <van-cell
                  v-for="report in reportList"
                  :key="report.id"
                  :border="false"
                  class="report-plan-list__item"
                >
                  <template #default>
                    <div class="report-plan-list__item__header">
                      <div class="report-plan-list__item__header__left">
                        <p>放映报告{{ report.id }}</p>
                        <p class="time">
                          {{ report.createTime }}
                          <!--                            | {{ report.distance }}-->
                        </p>
                        <p class="time" v-if="report.isUrgent">
                          {{ report.urgentTime }}
                        </p>
                        <p class="time" v-if="![0,1].includes(report.supervisionStatus)">
                          督办时间：{{ report.supervisionStatus==2 ? report.supervisionTime : '未督办' }}
                        </p>
                      </div>
                      <div>
                        <van-button
                          type="info"
                          style="width: 100px; height: 36px"
                          round
                          plain
                          @click="goToReportDetail(report)"
                        >
                          立即审核
                        </van-button>
                      </div>
                    </div>
                  </template>
                </van-cell>
              </van-list>
              <div v-else>
                <van-empty
                  :show="true"
                  description="该地区暂无放映计划"
                ></van-empty>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab>
          <template #title>
            <van-button
              @click="activeIndex = 1"
              block
              style="width: 148px"
              :type="activeIndex == 1 ? 'info' : 'default'"
              size="small"
              :color="activeIndex == 1 ? '#1492FF' : '#B3B5B9'"
              >已审批</van-button
            >
          </template>
          <div class="report-plan__content">
            <div class="report-plan-list" v-if="reportList.length > 0">
              <van-cell
                  v-for="report in reportList"
                  :key="report.id"
                  :border="false"
                  class="report-plan-list__item"
                  @click="goToReportDetail(report,true)"
                >
                  <template #default>
                    <div class="report-plan-list__item__header">
                      <div class="report-plan-list__item__header__left">
                        <p>放映报告{{ report.id }}</p>
                        <p class="time">
                          {{ report.createTime }}
                          <!--                            | {{ report.distance }}-->
                        </p>
                        <p class="time" v-if="report.isUrgent">
                          {{ report.urgentTime }}
                        </p>
                        <p class="time" v-if="![0,1].includes(report.supervisionStatus)">
                          督办时间：{{ report.supervisionStatus==2 ? report.supervisionTime : '未督办' }}
                        </p>
                      </div>
                      <div>
                        <van-icon
                          name="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d12590856.png"
                          size="70"
                          color="#19BE6B"
                          />
                      </div>
                    </div>
                  </template>
                </van-cell>
            </div>
            <div v-else>
              <van-empty
                :show="true"
                description="该地区暂无放映计划"
              ></van-empty>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div>
      <!--              {{showDateSelect}}-->
    </div>
  </div>
</template>

<script>
import { api } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'reportPlan',
  data() {
    return {
      activeIndex: 0,
      //前30天日期
      minDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      maxDate: new Date(),
      showAreaSelect: false,
      showDateSelect: false,

      cityName: '请选择',
      loading: false,
      finished: false,
      query: {
        status: 1, //1-待审核 3-已审核
        pageNo: 1,
        pageSize: 5,
      },

      // 报告列表
      reportList: [],
    }
  },
  components: {},
  mounted() {
    this.onLoad()
  },
  methods: {
    // initPage() {
    //   this.getSessionsList()
    // },
    onLoad() {
      console.log('onLoad')
      this.getSessionsList()
    },
    showArea: function () {
      this.showAreaSelect = true
    },
    // 获取放映报告列表
    getSessionsList() {
      this.loading = true
      api.groupRepresent.getReportList(this.query).then((res) => {
        this.loading = false
        console.log({ res })
        const { success, result } = res
        if (success) {
          this.reportList.push(...result.records)
          if (result.records.length < this.query.pageSize) {
            this.finished = true
          }
        } else {
          Toast('获取放映报告列表失败')
        }
      })
    },
    // 跳转到放映报告详情页
    goToReportDetail(item,detail=false) {
      this.$router.push({
        path: `/group/representApproval/detail/${item.id}?detail=${detail}`,
      })
    },
  },
  filters: {
    // 过滤器
    // 文字长度过滤
    textLength(text) {
      if (text.length > 4) {
        return text.slice(0, 4) + '...'
      } else {
        return text
      }
    },
  },
  watch: {
    activeIndex(val) {
      this.query.status = val === 0 ? 1 : 3
      this.query.pageNo = 1
      this.query.pageSize = 5
      this.reportList = []
      this.getSessionsList()
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-dropdown-menu__bar {
  box-shadow: none;
}

.report-plan {
  min-height: 100vh;
  background: #f6f7f8;
  padding-bottom: 1vh;
  &__header {
    padding: 16px 16px 0;
  }

  &__content {
    margin: 10px auto;
    border-radius: @border-radius-base;
  }

  &-list {
    height: 85vh;
    overflow-y: auto;
    &__item {
      background: #ffffff;
      margin-right: auto;
      margin-bottom: 10px;
      border-radius: 6px;
      border: 1px solid #e8e9ec;
      width: 96%;
      &__header {
        background: #fff;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        p {
          margin: 0;
          padding: 5px;
          font-size: 14px;
          padding: 4px;
        }
        .time {
          color: #686b73;
        }
        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.header {
  position: relative;

  &-name {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323233;
    & > div {
      border: 1px solid red;
    }
    span::after {
      position: absolute;
      top: 25px;
      margin-left: 5px;
      margin-top: -5px;
      border: 3px solid;
      border-color: transparent transparent #dcdee0 #dcdee0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0.8;
      content: ' ';
    }
  }
}
::v-deep .van-cell {
  padding: 8px;
  margin: auto 8px 8px;
}
</style>
